import React, { useState, useEffect } from "react";

import PollTable from "../Components/PollTable/PollTable";
import {
  Box,
  Button,
  Container,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
//import { polls } from "../constants/data";
import SearchIcon from "@mui/icons-material/Search";
import CustomTable from "../Components/shared/CustomTable";
import { POLL_TABLE_COLUMNS } from "../constants";
import Header from "../Components/Header/Header";
import {
  getPollsData,
  searchPollData,
} from "../Components/APIRequests/PollAPIs";
import Loader from "../Components/Loader/Loader";
import CreateButton from "../Components/buttons/CreateButton";
import DiscordButtons from "../Components/DiscordButtons/DiscordButtons";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import debounce from "lodash/debounce";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Polls = () => {
  const [showChart, setShowChart] = useState(false);
  const [open, setOpen] = useState(false);
  const [pollsData, setPollsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPaginations, setTotalPaginations] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [pollPaginationData, setPollPaginationData] = useState({
    limit: 10,
    offset: 0,
    search: "",
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const pageChangeHandler = async (event, pageNumber) => {
    setPollPaginationData({
      //...pollPaginationData,
      limit: 10,
      offset: pageNumber,
      search: "",
    });
    setSelectedPage(pageNumber);
    setLoading(true); // Set loading state to true when changing pages
    await getPollsAPICall({ ...pollPaginationData, offset: pageNumber - 1 });
    setLoading(false); // Set loading state back to false when the data is fetched
  };

  const handleViewResultClick = (poll) => {
    // Implement view result logic here
    handleOpen();
    console.log("View result clicked for poll:", poll);
  };
  const getPollsAPICall = async (data) => {
    setLoading(true);
    const polls = await getPollsData(data);
    console.log(polls, "The polls coming from backend");
    setPollsData(polls);
    let totalPage = polls?.totalCount / 10;
    // let totalPage = 3;
    totalPage = Math.ceil(totalPage);
    setTotalPaginations(totalPage);

    console.log(pollsData, "Polls state");
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const debouncedSearch = debounce(async (e) => {
    try {
      setLoading(true);
      console.log(e.target.value); // Assuming you're using e.target.value for the search query

      const searchBody = {
        limit: 20,
        offset: 0,
        search: e.target.value,
      };

      const searchedData = await searchPollData(searchBody);
      if (e.target.value === null || e.target.value.trim() === "") {
        getPollsAPICall({ limit: 10, offset: 0, search: "" });
      } else if (!searchedData) {
        toast.error("User not found!");
      } else {
        setPollsData(searchedData);
        console.log(searchedData, "Response of search");
        console.log(searchedData.totalCount, "This is the total count");
        let totalPage = 0;
        if (searchedData.totalCount < 10) {
          totalPage = 1;
        } else {
          totalPage = pollsData?.totalCount / 10;
        }
        // totalPage = pollsData?.totalCount / 10;
        // let totalPage = 3;
        totalPage = Math.ceil(totalPage);
        setTotalPaginations(totalPage);
        // setTotalPaginations(searchedData.totalCount);
        console.log(pollsData, "Searched data");
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
      alert("An error occurred while searching for the user.");
    }
  }, 500);

  const generelisedSearch = async (e) => {
    debouncedSearch(e);
  };

  useEffect(() => {
    getPollsAPICall({ limit: 10, offset: 0, search: "" });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      console.log(pollsData);
    }, 3000);
  }, []);
  return (
    // <Box
    //   sx={{
    //     width: "80vw",
    //     display: "flex",
    //     flexDirection: "column",
    //     justifyContent: "right",
    //     marginLeft: "17.5vw",
    //   }}
    // >
    //   <Header heading={"Polls"} />
    //   <PollTable polls={pollsData} onViewResultClick={handleViewResultClick} />
    //   {/* <CustomTable columns={POLL_TABLE_COLUMNS} rows={polls} /> */}
    // </Box>
    <>
      <Box
        sx={{
          width: "100vw",
          height: "12vh",
          background: "#2c2c2c",
        }}
      >
        <Grid container>
          <Grid xs={6}>
            <Box
              sx={{
                paddingLeft: "17.5vw",
              }}
            >
              <Header heading={"POLLS"} />
            </Box>
          </Grid>
          <Grid
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <form style={{}}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "right",
                    // marginRight: "40px",
                  }}
                >
                  <TextField
                    className="font"
                    id="filled-basic"
                    label="Search By Poll Title..."
                    variant="filled"
                    fullWidth
                    size="small"
                    onChange={(e) => {
                      generelisedSearch(e);
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#EFE1D1",
                      },
                    }}
                    SelectProps={{
                      style: {
                        color: "#EFE1D1",
                      },
                    }}
                    inputProps={{ style: { color: "#EFE1D1" } }}
                    sx={{
                      color: "#EFE1D1",
                      background: "#2c2c2c",
                      borderRadius: "2px",
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EFE1D1",
                        },
                      },

                      height: "35px", // Equal height with the button
                    }}
                  />
                  {/* <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={(e) => generelisedSearch(e)}
                    sx={{
                      background: "#331D2C",
                      color: "white",
                      height: "45px", // Equal height with the search field
                      marginLeft: "8px", // Add spacing between the search field and button
                      "&:hover": {
                        // backgroundColor: "#EFE1D1", // Change to your desired hover color
                        // color: "#2c2c2c",
                        backgroundColor: "#331D2C",
                      },
                    }}
                  >
                    <SearchIcon />
                  </Button> */}
                  <Button
                    variant="contained"
                    color="primary"
                    // type="submit"
                    //onClick={(e) => console.log("button clicked!")}
                    sx={{
                      background: "#331D2C",
                      height: "45px", // Equal height with the search field
                      marginLeft: "8px", // Add spacing between the search field and button
                      "&:hover": {
                        // backgroundColor: "#EFE1D1", // Change to your desired hover color
                        // color: "#2c2c2c",
                        background: "#331D2C",
                      },
                    }}
                  >
                    <SearchIcon />
                  </Button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {loading ? (
        <Box
          sx={{
            width: "80vw",
            display: "flex",
            justifyContent: "center",
            height: "60vh",
            alignItems: "center",
            marginLeft: "17.5vw",
          }}
        >
          <Loader />
        </Box>
      ) : (
        <Box
          sx={{
            width: "80vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "right",
            marginLeft: "17.5vw",
          }}
        >
          {/* <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "right",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "left",
              }}
            >
              <Header heading={"Polls"} />
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "right",
                height: 100,
                alignItems: "center",
              }}
            >
              <CreateButton />
            </Box>
          </Box> */}
          <Box
            sx={
              {
                // marginY: "30px",
                //width: "100",
              }
            }
          >
            <DiscordButtons />
          </Box>
          {pollsData ? (
            <PollTable
              polls={pollsData}
              onViewResultClick={handleViewResultClick}
            />
          ) : (
            <Typography
              sx={{
                color: "white",
                textAlign: "center",
                fontSize: 18,
                fontWeight: 800,
              }}
            >
              No data found
            </Typography>
          )}

          <Stack
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "right",
              marginTop: "10px",
            }}
          >
            <Pagination
              count={totalPaginations}
              onChange={(event, pageNumber) =>
                pageChangeHandler(event, pageNumber)
              }
              page={selectedPage}
              variant="outlined"
              shape="rounded"
              sx={{
                display: "flex",
                justifyContent: "right",
                marginTop: "10px",
                "& .MuiPaginationItem-page": {
                  color: "#EFE1D1", // Change button text color
                },
                // "& .MuiPaginationItem-root.Mui-selected": {
                //   backgroundColor: "black", // Change selected button background color
                // },
                "& .MuiPaginationItem-root": {
                  borderColor: "#EFE1D1", // Change button border color
                },
                "& .Mui-selected": {
                  //backgroundColor: "#2c2c2c", // Change selected button background color
                  background: "#EFE1D1 !important", // Change selected button background color
                  color: "#2c2c2c !important",
                },

                "& .MuiPaginationItem-icon": {
                  color: "#EFE1D1", // Change arrow color
                },
                "& .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected":
                  {
                    background: "#EFE1D1 !important", // Change selected button background color
                    color: "#2C2C2C !important",
                  },
                // "& .css-1wh1znk .Mui-selected ": {
                //   //backgroundColor: "#2C2C2C", // Change selected button background color
                //   background: "#EFE1D1 !important", // Change selected button background color
                //   color: "#2C2C2C",
                // },
              }}
            />
          </Stack>
          {/* <CustomTable columns={POLL_TABLE_COLUMNS} rows={polls} /> */}
          <Box sx={{}}> </Box>
        </Box>
      )}
    </>
  );
};

export default Polls;
