import axios from "axios";
import { base_url } from "../../base_url";
const getCollectionsURL = `${base_url}collection/get`;
export const buttonData = async (data) => {
  try {
    console.log("Create collection API call");
    //const { data } = await axios.get(`${base_url}collection/`);
    const response = await axios.post(`${base_url}/button`, data);

    return response;
  } catch (e) {
    console.log(e);
  }
};
export const getButtonColorAPI = async () => {
  try {
    const { data } = await axios.get(`${base_url}button`);
    //const { data } = await axios.get(getCollectionsURL);
    console.log(data);
    return data;
  } catch (e) {
    console.log(e);
  }
};
