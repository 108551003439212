import { Button, TextField, Box, Grid, MenuItem } from "@mui/material";
import React, { useEffect } from "react";
import {
  buttonData,
  getButtonColorAPI,
} from "../APIRequests/DiscordButtonsAPIs";
import { useState } from "react";
import Loader from "../Loader/Loader";
const DiscordButtons = () => {
  const [buttonColor, setButtonColor] = useState();
  const [showColor, setShowColor] = useState("");
  const [uiButton, setUIButton] = useState("");
  const [loading, setLoading] = useState(false);
  const getColorFromValue = (buttonColor) => {
    switch (buttonColor) {
      case "primary":
        return "blue";
      case "Secondary":
        return "grey";
      case "Danger":
        return "red";
      case "Success":
        return "green";
      default:
        return ""; // Set a default color or leave it empty
    }
  };
  const callButtonAPI = async (color) => {
    try {
      const res = await buttonData({ name: color });
      console.log(res, "response");
      if (res) {
        getButtonColor();
      }
      //setButtonColor(color); // Update buttonColor immediately after API call
    } catch (e) {
      console.error(e);
    }
  };
  const getButtonColor = async () => {
    try {
      const color = await getButtonColorAPI();
      console.log(color[0].name);
      switch (color[0].name) {
        case "primary":
          setShowColor("blue");
          break;
        //return "blue";
        case "Secondary":
          setShowColor("grey");
          break;
        //return "grey";
        case "Danger":
          setShowColor("red");
          break;
        //return "red";
        case "Success":
          setShowColor("green");
          break;
        //return "green";
        default:
          break;
        //  return ""; // Set a default color or leave it empty
      }
    } catch (e) {
      console.log(e);
    }
  };
  const setColor = () => {
    callButtonAPI(buttonColor);
  };
  const handleChange = (event) => {
    setButtonColor(event.target.value);
    console.log(event.target.value, "button");
    setUIButton(getColorFromValue(event.target.value));
  };
  // useEffect(() => {
  //   getButtonColor();
  // }, [showColor]);

  useEffect(() => {
    getButtonColor(); // Initialize showColor when the component mounts
  }, []);

  useEffect(() => {
    // When showColor changes, the button's color will be updated
    setUIButton(showColor);
  }, [showColor]);
  return (
    // <Box
    //   sx={{
    //     marginLeft: "300px",
    //   }}
    // >
    <Grid
      container
      spacing={2}
      sx={{
        //marginTop: "5vh",
        padding: "15px",
      }}
    >
      {" "}
      <Grid item lg={4}>
        <TextField
          select
          value={buttonColor}
          label="Select color of voting button"
          fullWidth
          size="small"
          onChange={handleChange}
          variant="filled"
          InputLabelProps={{
            style: {
              color: "#EFE1D1",
            },
          }}
          SelectProps={{
            style: {
              color: "#EFE1D1",
            },
          }}
          sx={{
            color: "#EFE1D1",
            background: "#2c2c2c",
            borderRadius: "2px",
            "& .MuiOutlinedInput-root": {
              // borderColor: "#EFE1D1",
              "&.Mui-focused fieldset": {
                // border: "none",
                borderColor: "#EFE1D1",
              },
            },
          }}
        >
          <MenuItem value={"primary"}>Blue</MenuItem>
          <MenuItem value={"Secondary"}>Grey</MenuItem>
          <MenuItem value={"Danger"}>Red</MenuItem>
          <MenuItem value={"Success"}>Green</MenuItem>
        </TextField>
      </Grid>
      <Grid item lg={4}>
        <Button
          sx={{
            //backgroundColor: uiButton,
            backgroundColor: showColor,
            // marginTop: "3%",
            color: "white",
            height: "45px",
          }}
          onClick={(e) => callButtonAPI(buttonColor)}
        >
          Save
        </Button>
      </Grid>
      <Grid item lg={4} />
    </Grid>
    // </Box>
  );
};

export default DiscordButtons;
