import React, { useState, useEffect } from "react";
import axios from "axios";
import { base_url } from "../../base_url";
const getPollsURL = `${base_url}/poll`;
//const getVotesPerPolls =   `${base_url}`
export const getPollsData = async (data) => {
  try {
    console.log("Getting Polls with pagination");
    const response = await axios.post(`${base_url}poll`, data);
    console.log(response);
    return response.data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const getVotesPerPoll = async (id) => {
  try {
    const data = await axios.post(`${base_url}/poll/${id}`);
    console.log(data);
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const searchPollData = async (poll) => {
  try {
    console.log("searching this user: ", poll);
    const { data } = await axios.post(`${base_url}poll/`, poll);
    console.log("response: ", data);

    if (!data) {
      // throw new Error("User not found"); // Throw an error if user is not found
      console.log("Poll not found!");
    }

    return data;
  } catch (e) {
    console.log(e);
    // throw e; // Re-throw the error so it can be caught elsewhere
  }
};
export const getUserVotesWithPagination = async (id, data) => {
  try {
    console.log("Getting user data with pagination");
    console.log("Getting user data with pagination");
    console.log("Getting user data with pagination");
    console.log("id", id);
    console.log("data", data);
    const response = await axios.post(`${base_url}poll/${id}`, data);
    // console.log(response);
    // console.log(response.data);
    return response.data;
  } catch (e) {
    console.log(e);
    return null; // Handle errors appropriately and return null or an error message
  }
};
