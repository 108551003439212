import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import debounce from "lodash/debounce";
import {
  createUserData,
  getUserData,
  updateUserData,
  deleteUserData,
  searchUserData,
  getUsersWithPagination,
  updateUserRecord,
} from "../Components/APIRequests/UserApis";
import UsersTable from "../Components/UserTable/UserTable";
import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  MenuItem,
} from "@mui/material";
import CreateButton from "../Components/buttons/CreateButton";
import Loader from "../Components/Loader/Loader";
import MyModal from "../Components/Modal/Modal";
import Form from "../Components/forms/Form";
import Header from "../Components/Header/Header";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
const Users = () => {
  const UserFields = [
    // { label: "Wallet Address", name: "wallet" },
    //{ label: "Discord ID", name: "discord_id" },
    { label: "Role", name: "roles" },
    // { label: "User Role", name: "user_role" },
    //{ label: "Updated By", name: "updated_by" },
  ];
  const initialFormData = {
    //wallet: "",
    // discord_id: "",
    roles: "",
    //user_role: "",
    // updated_by: "",
  };
  const inputStyle = {
    marginBottom: "15px",
  };
  const [userState, setUserState] = useState([]);
  const [searchedUser, setSearchedUser] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editeable, setEditeable] = useState(false);
  const [deleteable, setDeleteable] = useState(false);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [search, setSearch] = useState(null);
  const [role, setRole] = useState("user");
  const [isChecked, setIsChecked] = useState(false);
  const [searchNotFoundModal, setSearchNotFoundModal] = useState(false);
  const [totalPaginations, setTotalPaginations] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [userPaginationData, setUserPaginationData] = useState({
    limit: 10,
    offset: 0,
    search: "",
  });
  const [searchBodyOfUser, setSearchBodyOfUser] = useState({
    limit: "",
    offset: "",
    search: "",
  });
  const handleCreateData = async (_data) => {
    // Handle create data submission logic
    console.log("Form submitted with data:", _data);
    await createUserData(_data);
  };
  // const debouncedSearch = debounce(async (e) => {
  //   try {
  //     setLoading(true);
  //     console.log(e.target.value); // Assuming you're using e.target.value for the search query
  //     setSearchBodyOfUser({
  //       limit: userState.totalCount,
  //       offset: "",
  //       search: e.target.value,
  //     });
  //     console.log(searchBodyOfUser);
  //     if (searchBodyOfUser == null) {
  //       getUsers();
  //     }
  //     const searchedData = await searchUserData(searchBodyOfUser);

  //     if (!searchedData) {
  //       toast.error("User not found!");
  //     } else {
  //       setUserState(searchedData);
  //       console.log(userState, "Searched data");
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     console.error(error);
  //     alert("An error occurred while searching for the user.");
  //   }
  // }, 2000); // Adjust the debounce delay (in milliseconds) as needed

  const debouncedSearch = debounce(async (e) => {
    try {
      setLoading(true);
      console.log(e.target.value); // Assuming you're using e.target.value for the search query

      const searchBody = {
        limit: 10,
        offset: "",
        search: e.target.value,
      };

      const searchedData = await searchUserData(searchBody);
      if (e.target.value === null || e.target.value.trim() === "") {
        getUsers();
      } else if (!searchedData) {
        toast.error("User not found!");
      } else {
        setUserState(searchedData);
        console.log(userState, "Searched data");
        console.log(searchedData, "Response of search");
        console.log(searchedData.totalCount, "This is the total count");
        let totalPage = 0;
        if (searchedData.totalCount < 10) {
          totalPage = 1;
        } else {
          totalPage = searchedData?.totalCount / 10;
        }
        // totalPage = pollsData?.totalCount / 10;
        // let totalPage = 3;
        totalPage = Math.ceil(totalPage);
        setTotalPaginations(totalPage);
        // setTotalPaginations(searchedData.totalCount);
      }

      setLoading(false);
    } catch (error) {
      console.error(error);
      alert("An error occurred while searching for the user.");
    }
  }, 500);

  const generelisedSearch = async (e) => {
    debouncedSearch(e);
  };
  const getUsers = async (data) => {
    setLoading(true);
    //const users = await getUserData();
    const users = await getUsersWithPagination(data);
    console.log(users);
    let totalPage = 0;
    if (users.totalCount < 10) {
      totalPage = 1;
    } else {
      totalPage = users?.totalCount / 10;
    }
    totalPage = Math.ceil(totalPage);
    setTotalPaginations(totalPage);
    setUserState(users);
    console.log(userState);
    setTimeout(() => {
      setLoading(false);
      console.log(userState);
    }, 2000);
  };
  // const pageChangeHandler = async (event, pageNumber) => {
  //   console.log(pageNumber, "This will be the offset value");
  //   setUserPaginationData({
  //     //limit: ,
  //     offset: pageNumber,
  //     search: "",
  //   });
  //   await getUsers(userPaginationData);
  // };

  const pageChangeHandler = async (event, pageNumber) => {
    setUserPaginationData({
      //...userPaginationData,
      limit: 10,
      offset: pageNumber,
    });
    setLoading(true); // Set loading state to true when changing pages
    setSelectedPage(pageNumber);
    await getUsers({ limit: 10, offset: pageNumber - 1, search: "" });
    setLoading(false); // Set loading state back to false when the data is fetched
  };
  const getUserId = (id) => {
    console.log("get user id function called!");
    setSelectedUserId(id);
    const currentUser = userState.users.find((user) => user.user_id === id);
    console.log(currentUser);
    if (currentUser) {
      console.log(currentUser.roles);
      setRole(currentUser.roles); // Set the current role
    }
    console.log(role);
    console.log("called getUserId function");
  };
  const handleUpdateData = async (_data) => {
    console.log("Form submitted with data:", _data);
    await updateUserData(selectedUserId, _data);
  };
  const handleDeleteData = async (selectedUserId) => {
    setLoading(true);

    console.log("deleting this id:", selectedUserId);
    await deleteUserData(selectedUserId);
    setLoading(false);
    setDeleteable(false);
    getUsers();
    toast.success("User deleted successfully!");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // You can handle form submission here, e.g., sending data to a server
    await createUserData(formData);
    setShowModal(false);
    getUsers();
    console.log("Form submitted with data:", formData);
  };

  const handleSearchSubmit = async (event) => {
    // try {
    //   setLoading(true);
    //   event.preventDefault();
    //   console.log(search);
    //   const searchedData = await searchUserData(search);

    //   if (!searchedData) {
    //     //alert("No user found!");
    //     //setSearchNotFoundModal(true);
    //     toast.error("User not found!");
    //   } else {
    //     setUserState([searchedData]);
    //   }

    //   setLoading(false);
    // } catch (e) {
    //   console.log(e);
    //   alert("An error occurred while searching for the user.");
    // }

    event.preventDefault();
    const searchBody = {
      limit: 10,
      offset: "",
      search: event.target.value,
    };
    const users = await getUsersWithPagination(searchBody);
    setUserState(users);
  };
  // const handleSwitchChange = async (event) => {
  //   setIsChecked(event.target.checked);
  //   if (isChecked) {
  //     setRole("admin");
  //     console.log(role);
  //   } else {
  //     setRole("user");
  //     console.log(role);
  //   }
  //   //updateUserData()
  //   console.log("Role changed to: ", role);
  // };
  const handleUpdateSubmit = async (event) => {
    event.preventDefault();
    console.log("The date am sending to API is: ", formData);
    setFormData({ roles: role });
    await updateUserData(selectedUserId, { roles: role });
    setEditeable(false);
    getUsers();
    console.log("Form submitted with data:", formData);
    toast.success("User updated successfully!");
  };
  const handleRoleChange = (event) => {
    const newRole = event.target.value;
    setRole(newRole);
    //setRole(event.target.value);
    console.log(newRole, "will be updated role");
  };
  useEffect(() => {
    // getUsers();
    // getUsersWithPagination({
    //   limit: 10,
    //   offset: 0,
    //   search: "",
    // });
    getUsers({ limit: 10, offset: 0, search: "" });
  }, []);
  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "12vh",
          background: "#2c2c2c",
        }}
      >
        {/* <Box
          sx={{
            paddingLeft: "17.5vw",
          }}
        >
          <Header heading={"Users"} />
        </Box> */}
        <Grid container>
          <Grid xs={6}>
            <Box
              sx={{
                paddingLeft: "17.5vw",
              }}
            >
              <Header heading={"USERS"} />
            </Box>
          </Grid>

          <Grid
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={(e) => updateUserRecord()}
                sx={{
                  background: "#331D2C",
                  color: "#EFE1D1",
                  height: "45px", // Equal height with the search field
                  marginLeft: "0px", // Add spacing between the search field and button
                  marginRight: "10px",
                  "&:hover": {
                    backgroundColor: "#EFE1D1", // Change to your desired hover color
                    color: "#2c2c2c",
                  },
                }}
              >
                Update Records
              </Button>
              <form style={{}} onSubmit={handleSearchSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "right",
                    // marginRight: "40px",
                  }}
                >
                  <TextField
                    className="font"
                    id="filled-basic"
                    label="Search By Wallet Address..."
                    variant="filled"
                    fullWidth
                    size="small"
                    onChange={(e) => {
                      generelisedSearch(e);
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#EFE1D1",
                      },
                    }}
                    SelectProps={{
                      style: {
                        color: "#EFE1D1",
                      },
                    }}
                    inputProps={{ style: { color: "#EFE1D1" } }}
                    sx={{
                      color: "#EFE1D1",
                      background: "#2c2c2c",
                      borderRadius: "2px",
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EFE1D1",
                        },
                      },

                      height: "35px", // Equal height with the button
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    // type="submit"
                    //onClick={handleSearchSubmit}
                    sx={{
                      background: "#331D2C",
                      height: "45px", // Equal height with the search field
                      marginLeft: "8px", // Add spacing between the search field and button
                      "&:hover": {
                        // backgroundColor: "#EFE1D1", // Change to your desired hover color
                        // color: "#2c2c2c",
                        background: "#331D2C",
                      },
                    }}
                  >
                    <SearchIcon />
                  </Button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          //  marginTop: "30px",
          display: "flex",
          width: "100%",
          justifyContent: "right",
        }}
      >
        {/* <Grid
          container
          sx={{
            // width: "400px",
            marginTop: 8,
          }}
        >
          <Grid xs={8}></Grid>
          <Grid xs={4}>
            <form style={{}} onSubmit={handleSearchSubmit}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "40px",
                }}
              >
                <TextField
                  className="font"
                  id="filled-basic"
                  label="Search..."
                  variant="filled"
                  fullWidth
                  size="small"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  InputLabelProps={{
                    style: {
                      color: "#EFE1D1",
                    },
                  }}
                  SelectProps={{
                    style: {
                      color: "#EFE1D1",
                    },
                  }}
                  sx={{
                    color: "#EFE1D1",
                    background: "#2c2c2c",
                    borderRadius: "2px",
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#EFE1D1",
                      },
                    },
                    height: "35px", // Equal height with the button
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{
                    background: "#331D2C",
                    height: "35px", // Equal height with the search field
                    marginLeft: "8px", // Add spacing between the search field and button
                  }}
                >
                  <SearchIcon />
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid> */}
      </Box>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {loading ? (
        <Box
          sx={{
            width: "80vw",
            display: "flex",
            justifyContent: "center",
            height: "60vh",
            alignItems: "center",
            marginLeft: "17.5vw",
          }}
        >
          <Loader />
        </Box>
      ) : (
        <Box
          sx={{
            width: "80vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "right",
            marginLeft: "17.5vw",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "right",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "left",
                marginTop: "30px",
                // marginBottom: "60px",
              }}
            >
              {/* <Grid
                container
                sx={{
                  // width: "400px",
                  marginTop: 2,
                }}
              >
                <Grid xs={4}></Grid>
                <Grid xs={8}>
                  <form style={{}} onSubmit={handleSearchSubmit}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <TextField
                        className="font"
                        id="filled-basic"
                        label="Search..."
                        variant="filled"
                        fullWidth
                        size="small"
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        InputLabelProps={{
                          style: {
                            color: "#EFE1D1",
                          },
                        }}
                        SelectProps={{
                          style: {
                            color: "#EFE1D1",
                          },
                        }}
                        sx={{
                          color: "#EFE1D1",
                          background: "#2c2c2c",
                          borderRadius: "2px",
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#EFE1D1",
                            },
                          },
                          height: "35px", // Equal height with the button
                        }}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        sx={{
                          background: "#331D2C",
                          height: "35px", // Equal height with the search field
                          marginLeft: "8px", // Add spacing between the search field and button
                        }}
                      >
                        <SearchIcon />
                      </Button>
                    </Box>
                  </form>
                </Grid>
              </Grid> */}
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "right",
                height: 10,
                marginTop: 3,
                alignItems: "center",
                marginY: "50px",
              }}
            >
              <form style={{}} onSubmit={handleSearchSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "right",
                    // marginRight: "40px",
                  }}
                >
                  <TextField
                    className="font"
                    id="filled-basic"
                    label="Search By Wallet Address..."
                    variant="filled"
                    fullWidth
                    size="small"
                    onChange={(e) => {
                      generelisedSearch(e);
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#EFE1D1",
                      },
                    }}
                    SelectProps={{
                      style: {
                        color: "#EFE1D1",
                      },
                    }}
                    sx={{
                      color: "#EFE1D1",
                      background: "#2c2c2c",
                      borderRadius: "2px",
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#EFE1D1",
                        },
                      },
                      height: "35px", // Equal height with the button
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{
                      background: "#331D2C",
                      height: "45px", // Equal height with the search field
                      marginLeft: "8px", // Add spacing between the search field and button
                    }}
                  >
                    <SearchIcon />
                  </Button>
                </Box>
              </form>
            </Box> */}
          </Box>
          {userState ? (
            <UsersTable
              users={userState}
              editState={editeable}
              setEditState={setEditeable}
              deleteState={deleteable}
              setDeleteState={setDeleteable}
              openState={open}
              setOpenState={setOpen}
              onClick={getUserId}
            />
          ) : (
            <Typography
              sx={{
                color: "white",
                textAlign: "center",
                fontSize: 18,
                fontWeight: 800,
              }}
            >
              No data found
            </Typography>
          )}

          <Stack
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "right",
              marginTop: "10px",
            }}
          >
            <Pagination
              count={totalPaginations}
              page={selectedPage}
              onChange={(event, pageNumber) =>
                pageChangeHandler(event, pageNumber)
              }
              variant="outlined"
              shape="rounded"
              sx={{
                display: "flex",
                justifyContent: "right",
                marginTop: "10px",
                "& .MuiPaginationItem-page": {
                  color: "#EFE1D1", // Change button text color
                },
                // "& .MuiPaginationItem-root.Mui-selected": {
                //   backgroundColor: "black", // Change selected button background color
                // },
                "& .MuiPaginationItem-root": {
                  borderColor: "#EFE1D1", // Change button border color
                },
                "& .Mui-selected": {
                  //backgroundColor: "#2c2c2c", // Change selected button background color
                  background: "#EFE1D1 !important", // Change selected button background color
                  color: "#2c2c2c !important",
                },

                "& .MuiPaginationItem-icon": {
                  color: "#EFE1D1", // Change arrow color
                },
                "& .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected":
                  {
                    background: "#EFE1D1 !important", // Change selected button background color
                    color: "#2C2C2C !important",
                  },
                // "& .css-1wh1znk .Mui-selected ": {
                //   //backgroundColor: "#2C2C2C", // Change selected button background color
                //   background: "#EFE1D1 !important", // Change selected button background color
                //   color: "#2C2C2C",
                // },
              }}
            />
          </Stack>
          {/* <UsersTable users={searchedUser} /> */}
          {searchNotFoundModal && (
            <MyModal
              open={searchNotFoundModal}
              setOpen={setSearchNotFoundModal}
            >
              Searched result not found !
            </MyModal>
          )}
          {showModal && (
            <MyModal
              open={showModal}
              setOpen={setShowModal}
              // updateOpen={editeable}
              // setUpdateOpen={setEditeable}
            >
              {/* <Form
                closer={() => setShowModal(false)}
                getUsers={getUsers}
                fields={UserFields}
                onSubmit={handleCreateData}
                title="Create Users"
                callFunction={handleCreateData}
              /> */}
              <form onSubmit={handleSubmit}>
                {UserFields.map((field) => (
                  <TextField
                    key={field.name}
                    style={inputStyle}
                    label={field.label}
                    name={field.name}
                    value={formData[field.name]}
                    onChange={handleInputChange}
                    variant="outlined"
                    required
                    InputProps={{ disableUnderline: true }}
                  />
                ))}
                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
              </form>
            </MyModal>
          )}
          {editeable && (
            <MyModal
              open={editeable}
              setOpen={setEditeable}
              // updateOpen={editeable}
              // setUpdateOpen={setEditeable}
            >
              <form onSubmit={handleUpdateSubmit}>
                <Typography
                  // variant="h6"
                  sx={{ textAlign: "center", fontWeight: 600, fontSize: "14" }}
                >
                  Update User
                </Typography>
                {UserFields.map((field) => (
                  // <TextField
                  //   key={field.name}
                  //   style={inputStyle}
                  //   label={field.label}
                  //   name={field.name}
                  //   value={formData[field.name]}
                  //   onChange={handleInputChange}
                  //   variant="outlined"
                  //   required
                  //   InputProps={{ disableUnderline: true }}
                  // />
                  <Grid
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      flexDirection: "column",
                      marginY: "10px",
                    }}
                  >
                    <Grid
                      xs={12}
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      Change user role?
                    </Grid>
                    <Grid
                      xs={12}
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      {/* <Switch
                        checked={isChecked}
                        onChange={handleSwitchChange}
                      /> */}

                      <TextField
                        select
                        value={role}
                        label="Roles"
                        fullWidth
                        onChange={handleRoleChange}
                        variant="filled"
                        InputLabelProps={{
                          style: {
                            color: "#EFE1D1",
                          },
                        }}
                        SelectProps={{
                          style: {
                            color: "#EFE1D1",
                          },
                        }}
                        sx={{
                          color: "#EFE1D1",
                          background: "#2c2c2c",
                          borderRadius: "2px",
                          "& .MuiOutlinedInput-root": {
                            // borderColor: "#EFE1D1",
                            "&.Mui-focused fieldset": {
                              // border: "none",
                              borderColor: "#EFE1D1",
                            },
                          },
                        }}
                      >
                        <MenuItem value={"user"}>user</MenuItem>
                        <MenuItem value={"admin"}>admin</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid
                      xs={12}
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        sx={{ marginTop: "10px" }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                ))}
              </form>

              {/* ----------------------- */}
            </MyModal>
          )}
          {deleteable && (
            <MyModal
              open={deleteable}
              setOpen={setDeleteable}
              closer={() => setDeleteable(false)}
              getUsers={getUsers}
              // selectedCollectionId={selectedCollectionId}
              title="Update Collection"
              // callFunction={handleDeleteData}
              // updateOpen={editeable}
              // setUpdateOpen={setEditeable}
            >
              <Typography
                // variant="h6"
                sx={{ textAlign: "center", fontWeight: 600, fontSize: "14" }}
              >
                Delete User
              </Typography>
              <Grid container>
                <Grid xs={12}>
                  <Typography sx={{ color: "white" }}>
                    Are you sure you want to delete?
                  </Typography>
                </Grid>
                <Box sx={{ display: "flex", justifyContent: "right" }}>
                  <Button
                    sx={{ color: "white", marginLeft: "250px" }}
                    onClick={(e) => handleDeleteData(selectedUserId)}
                  >
                    Yes
                  </Button>
                  <Button
                    sx={{ color: "white" }}
                    onClick={(e) => setDeleteable(false)}
                  >
                    No
                  </Button>
                </Box>
              </Grid>
            </MyModal>
          )}
          {/* {open && <Modal />} */}
          {/* <CustomTable columns={POLL_TABLE_COLUMNS} rows={polls} /> */}
          <Box sx={{}}> </Box>
        </Box>
      )}
    </>
  );
};

export default Users;
