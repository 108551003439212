import React, { useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Box,
} from "@mui/material";
const PollChartDataTable = ({ data }) => {
  console.log(data, "Data coming from backend!!!");
  const cellStyle = {
    textAlign: "center",
    color: "#EFE1D1",
    fontWeight: "bold",
    padding: "2px 2px",
    height: "20px",
  };
  useEffect(() => {
    // console.log(data.userVotes, "in PollChartDataTable");
  }, []);

  return (
    <div>
      <TableContainer
        sx={{
          background: "#3e3e3e",
          border: "1px solid #ccc",
          borderRadius: "10px",
          width: "100%",
          //   marginLeft: "2vw",
          overflow: "hidden",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={cellStyle}>User Name</TableCell>
              <TableCell sx={cellStyle}>Title Option</TableCell>
              <TableCell sx={cellStyle}>Wallet Address</TableCell>
              <TableCell sx={cellStyle}>Voting Power</TableCell>
              {/* <TableCell sx={cellStyle}>User Roles Updated By</TableCell> */}
              {/* <TableCell sx={cellStyle}>Actions</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item, index) => (
              // <div>{JSON.stringify(user)}</div>

              <TableRow
                key={index}
                sx={{
                  background: "transparent",
                  "&:hover": {
                    background: "#2c2c2c",
                  },
                }}
              >
                <TableCell sx={cellStyle}>{item?.user_name}</TableCell>
                <TableCell sx={cellStyle}>{item?.option_title}</TableCell>
                <TableCell sx={cellStyle}>{item?.wallet}</TableCell>
                <TableCell sx={cellStyle}>{item?.voting_power}</TableCell>

                {/* <TableCell sx={cellStyle}>
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{
                      border: "none",
                      color: "#EFE1D1",
                      "&:hover": {
                        border: "none",
                        color: "#3e3e3e",
                        background: "#EFE1D1",
                      },
                    }}
                    onClick={() => handleEditClick(user)}
                  >
                    <EditIcon />
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={{
                      border: "none",
                      color: "#EFE1D1",
                      "&:hover": {
                        border: "none",
                        color: "#3e3e3e",
                        background: "#EFE1D1",
                      },
                    }}
                    onClick={() => handleDeleteClick(user)}
                  >
                    <DeleteIcon />
                  </Button>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PollChartDataTable;
