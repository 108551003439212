import { Box, Button, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LoginIcon from "@mui/icons-material/Login";
import { useNavigate } from "react-router-dom";
import { Xumm } from "xumm";
import { Header } from "../Header/Header";
import { axiosRequest } from "../Apis/Apis";
import { login } from "../APIRequests/UserApis";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/images/logo.png";
const callXummApiURL = "api/luxlion/nfts?userAccount=";

//const XUMM_API_KEY = process.env.REACT_APP_XUMM_API_KEY;
//const XUMM_API_KEY = "b71d235a-5b0a-4c06-aa55-55a0a0af17ad";

//b71d235a-5b0a-4c06-aa55-55a0a0af17ad //old API Key
var xumm = new Xumm(
  "de525034-8c63-4e60-8178-05b279b008b7", //client-API Key
  // "09c92d00-3e4f-4ef1-a446-870175c859ec",
  //"b71d235a-5b0a-4c06-aa55-55a0a0af17ad", //my-API Ke
  " 09c92d00-3e4f-4ef1-a446-870175c859ec"
  // "41eef4c3-3188-4314-95a9-f35db93204f5"
);
// var XUMM_API_KEY = new Xumm(
//   "2cc1e927-2d8e-4c9e-aa40-6ba3b75143e5",
//   "d44c0da1-63de-4acc-b223-ab1b58699588"
// );
//const Xumm = new Xumm(XUMM_API_KEY);

const Login = () => {
  const [loggedin, setLoggedIn] = useState(false);
  const [connectedAddress, setConnectedAddress] = useState(null);
  const [isNotAdmin, setIsNotAdmin] = useState(false);
  const [apiCalled, setApiCalled] = useState(0);
  const navigate = useNavigate();

  async function callXummApi(data) {
    try {
      const response = await axiosRequest(
        "get",
        `${callXummApiURL}${data}`,
        undefined,
        undefined
      );
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  }

  function XummLogin() {
    try {
      if (Xumm) {
        // console.log(xumm);
        // console.log(Xumm_API_KEY);
        connect();
      }
    } catch (e) {
      console.log(e);
    }
  }
  const callLoginAPI = async (userAcc) => {
    console.log(userAcc, "Sending this address to API");
    const res = await login({ userAccount: userAcc });
    console.log("account matched ", res);
    console.log(res?.data?.message, "data is here");
    console.log("navigate after check");
    // if (res?.data.message === true) {
    console.log("navigate from here");
    localStorage.setItem("address", userAcc);
    window.location.href = "/admin";

    navigate("/admin");
    // } else {
    //   // setApiCalled(apiCalled + 1);
    //   console.log("You are not authorized");
    //   localStorage.setItem("pkce_state", "");
    //   localStorage.setItem("XummPkceJwt", "");
    //   localStorage.setItem("address", "");
    //   // if (apiCalled > 1) {
    //   //   alert("Not authorised user!");
    //   // }

    //   // toast.error("Not authorised user!");
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 5000);
    //   setIsNotAdmin(true);
    // }
  };
  useEffect(() => {
    try {
      xumm.on("ready", () =>
        console.log("Ready (e.g. hide loading state of page)")
      );
      // We rely on promises in the `success` event: fired again if a user
      // logs out and logs back in again (resets all promises)
      xumm.on("error", () => {
        console.log("error");
      });
      xumm.on("success", async () => {
        xumm.user.account.then(async (account) => {
          console.log("connected ", account);
          await callLoginAPI(account);
          setConnectedAddress(account);
        });
      });
      xumm.on("logout", async () => {
        console.log("disconnected ");
        setConnectedAddress(null);
      });
    } catch (e) {
      alert(e);
    }
  }, []);
  function connect() {
    try {
      xumm.authorize();
      // callLoginAPI(connectedAddress);
    } catch (e) {
      console.log(e);
    }
  }
  function Logout() {
    try {
      xumm.logout();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "90px",
        }}
      >
        <img
          src={logo}
          style={{
            width: "90px",
            height: "90px",

            marginBottom: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: "#EFE1D1",
            marginTop: "2%",
            fontWeight: 800,
          }}
        >
          BAFC - BOT
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: "#EFE1D1",
            marginTop: "1%",
            fontWeight: 800,
          }}
        >
          Admin Panel
        </Typography>
      </Box>

      <Box
        sx={{
          // height: "90vh", // 100% of the viewport height
          paddingTop: "5%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          onClick={(e) => XummLogin()} // Call XummLogin function on button click
          sx={{
            fontWeight: 1000,
            color: "#EFE1D1",
            background: "#331D2C",
          }}
        >
          Login with XUMM
          <LoginIcon />
        </Button>

        {/* {connectedAddress && <p>Connected to {connectedAddress}</p>}
      {connectedAddress && <button onClick={Logout}>Logout</button>} */}
      </Box>

      {isNotAdmin && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            padding: "5px 10px",

            marginTop: "5px",
          }}
        >
          <span
            style={{
              background: "#EFE1D1",
              padding: "2px",
            }}
          >
            <Typography
              sx={{
                color: "red",
                //marginTop: "100px",
                display: "flex",
                justifyContent: "center",
                fontWeight: 900,
                padding: "0px",
              }}
            >
              You are not an admin!
            </Typography>
          </span>
        </Box>
      )}
    </>
  );
};

export default Login;
