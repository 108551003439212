import React, { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import {
  createCollectionData,
  getCollectionsData,
  updateCollectionData,
  deleteCollectionData,
  getCollectionDataById,
} from "../Components/APIRequests/CollectionAPIs";
import CollectionTable from "../Components/CollectionTable/CollectionTable";
import Header from "../Components/Header/Header";
import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import CreateButton from "../Components/buttons/CreateButton";
import Loader from "../Components/Loader/Loader";
import MyModal from "../Components/Modal/Modal";
import Form from "../Components/forms/Form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Collection = () => {
  const collectionFields = [
    { label: "Collection Name", name: "collection_name" },
    { label: "TaxOnId", name: "taxonId" },
    { label: "Roles", name: "role" },
    { label: "Weight", name: "weight" },
  ];
  const [collectionState, setCollectionState] = useState([]);
  const [selectedCollectionId, setSelectedCollectionId] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editeable, setEditeable] = useState(false);
  const [deleteable, setDeleteable] = useState(false);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    collection_name: "",
    taxonid: 0,
    role: "",
    weight: 0,
  });
  const notify = () => toast("New collection created!");
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const getCollectionId = async (id) => {
    setLoading(true);
    setSelectedCollectionId(id);
    console.log("called getCollectionId function");
    const col = await getCollectionDataById(id);
    // setFormData({
    //   collection_name: selectedCollection?.collection_name || "",
    //   taxonid: selectedCollection?.taxonid || "",
    //   role: selectedCollection?.role || "",
    //   weight: selectedCollection?.weight || "",
    // });
    setFormData({
      collection_name: col?.collection_name || "",
      taxonid: col?.taxonid || "",
      role: col?.role || "",
      weight: col?.weight == 0 ? 0 : col?.weight || "",
    });
    setSelectedCollection(col);
    console.log("Got collection by ID", formData);
    setLoading(false);
  };
  const getCollections = async () => {
    setLoading(true);
    const collections = await getCollectionsData();
    setCollectionState(collections);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };
  const handleCreateDataModal = () => {
    setFormData({
      collection_name: "",
      taxonid: 0,
      role: "",
      weight: 0,
    });
    setShowModal(true);
  };
  const handleCreateData = async (event) => {
    event.preventDefault();
    const isTaxonIdValid =
      /^\d+$/.test(formData.taxonid) &&
      formData.taxonid > 0 &&
      formData.taxonid.length < 8; // Checks if it's a positive integer
    const isWeightNumeric =
      !isNaN(formData.weight) && formData.weight >= 0 && formData.weight <= 99;

    if (isTaxonIdValid && isWeightNumeric) {
      if (!isNaN(formData.taxonid) && !isNaN(formData.weight)) {
        console.log(formData, ": This is the data coming to send backend");
        formData.taxonid = parseInt(formData.taxonid);
        await createCollectionData(formData);
        // await createCollectionData();
        setFormData({
          collection_name: "",
          taxonid: 0,
          role: "",
          weight: 0,
        });
        console.log("Form submitted with data:");
        setShowModal(false);

        getCollections();
        toast.success("Collection created successfully!");
      } else {
        // Handle validation error (e.g., show an error message)
        alert("Taxon ID and Weight must be numeric values.");
      }
    } else {
      // Handle validation error
      if (!isTaxonIdValid) {
        alert(
          "Taxon ID must be a positive integer, can't be 0 and max length is 8 characters."
        );
      } else if (!isWeightNumeric) {
        alert("Weight must be a positive numeric value between 0-99.");
      }
    }

    // Handle create data submission logic
  };
  const handleUpdateData = async (_data) => {
    console.log("Form submitted with data:", _data);
    await updateCollectionData(selectedCollectionId, _data);
    //setEditeable(false);
  };
  const handleDeleteData = async (selectedCollectionId) => {
    setLoading(true);
    console.log("deleting this id:", selectedCollectionId);
    await deleteCollectionData(selectedCollectionId);
    setLoading(false);
    setDeleteable(false);
    getCollections();
    toast.success("Collection deleted successfully!");
  };
  /*----------------Edit form functions----------------*/

  // const handleUpdateSubmit = async (event) => {
  //   event.preventDefault();
  //   // Check if taxonId and weight are numeric
  //   if (!isNaN(formData.taxonid) && !isNaN(formData.weight)) {
  //     // await callFunction(formData);
  //     await updateCollectionData(selectedCollectionId, formData);
  //     setTimeout(() => {}, 1000);
  //     await getCollections();
  //     setFormData({
  //       collection_name: "",
  //       taxonid: 0,
  //       role: "",
  //       weight: 0,
  //     });
  //     setEditeable(false);
  //     toast.success("Collection updated successfully!");
  //     // closer();
  //   } else {
  //     // Handle validation error (e.g., show an error message)
  //     alert("Taxon ID and Weight must be numeric values.");
  //   }
  // };

  const handleUpdateSubmit = async (event) => {
    event.preventDefault();
    // Check if taxonId is a positive integer and weight is numeric
    const isTaxonIdValid =
      /^\d+$/.test(formData.taxonid) &&
      formData.taxonid > 0 && // Checks if it's a positive integer
      formData.taxonid.toString().length < 8;

    const isWeightNumeric =
      !isNaN(formData.weight) && formData.weight >= 0 && formData.weight <= 99;

    let data = { ...formData };
    console.log({ data });
    if (isTaxonIdValid && isWeightNumeric) {
      // Valid values

      // Proceed with your update logic
      await updateCollectionData(selectedCollectionId, data);
      setTimeout(() => {}, 1000);
      await getCollections();
      setFormData({
        collection_name: "",
        taxonid: 0,
        role: "",
        weight: 0,
      });

      setEditeable(false);
      toast.success("Collection updated successfully!");
      // closer();
    } else {
      // Handle validation error
      if (!isTaxonIdValid) {
        alert(
          "Taxon ID must be a positive integer, can't be 0 and max length is 8 characters."
        );
      } else if (!isWeightNumeric) {
        alert("Weight must be a positive numeric value between 0-99.");
      }
    }
  };

  const handleInputChange = (event) => {
    console.log("input change");
    const { name, value } = event.target;
    console.log("input change ", { name, value });

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {
    getCollections();
  }, []);
  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "12vh",
          background: "#2c2c2c",
        }}
      >
        <Grid container>
          <Grid xs={6}>
            <Box
              sx={{
                paddingLeft: "17.5vw",
              }}
            >
              <Header heading={"COLLECTIONS"} />
            </Box>
          </Grid>
          <Grid
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "30px",
              }}
            >
              <CreateButton modelToggler={(val) => handleCreateDataModal()} />
            </Box>
          </Grid>
        </Grid>
        {/* <Box
          sx={{
            paddingLeft: "17.5vw",
          }}
        >
          <Header heading={"Collections"} />
        </Box> */}
      </Box>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {/* Same as */}
      {loading ? (
        <Box
          sx={{
            width: "80vw",
            display: "flex",
            justifyContent: "center",
            height: "60vh",
            alignItems: "center",
            marginLeft: "17.5vw",
          }}
        >
          <Loader />
        </Box>
      ) : (
        <Box
          sx={{
            width: "80vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "right",
            marginLeft: "17.5vw",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "right",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "left",
              }}
            >
              {/* <Header heading={"Collections"} /> */}
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "right",
                height: 100,
                alignItems: "center",
              }}
            >
              <CreateButton modelToggler={(val) => handleCreateDataModal()} />
            </Box> */}
          </Box>
          <Box
            sx={{
              marginTop: "30px",
            }}
          >
            {collectionState ? (
              <CollectionTable
                collections={collectionState}
                editState={editeable}
                setEditState={setEditeable}
                deleteState={deleteable}
                setDeleteState={setDeleteable}
                openState={open}
                setOpenState={setOpen}
                onClick={getCollectionId}
              />
            ) : (
              <Typography
                sx={{
                  color: "white",
                  textAlign: "center",
                  fontSize: 18,
                  fontWeight: 800,
                }}
              >
                No data found
              </Typography>
            )}
          </Box>

          {showModal && (
            <MyModal open={showModal} setOpen={setShowModal}>
              <form onSubmit={(e) => handleCreateData(e, formData)}>
                <Typography
                  // variant="h6"
                  sx={{
                    textAlign: "center",
                    fontWeight: 600,
                    fontSize: "14",
                    marginBottom: 2,
                  }}
                >
                  Create Collection
                </Typography>
                <TextField
                  label="Collection Name"
                  name="collection_name"
                  value={formData.collection_name}
                  onChange={handleInputChange}
                  variant="outlined"
                  required
                  sx={{
                    marginBottom: 2,
                    width: "100%",
                    "& input, & label": {
                      color: "#EFE1D1",
                    },
                    "& fieldset": {
                      borderColor: "#EFE1D1",
                    },
                    "&:hover fieldset": {
                      borderColor: "#EFE1D1",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#EFE1D1",
                    },
                  }}
                />
                <TextField
                  label="Taxon ID"
                  name="taxonid"
                  value={formData.taxonid}
                  onChange={handleInputChange}
                  variant="outlined"
                  required
                  sx={{
                    marginBottom: 2,
                    width: "100%",
                    "& input, & label": {
                      color: "#EFE1D1",
                    },
                    "& fieldset": {
                      borderColor: "#EFE1D1",
                    },
                    "&:hover fieldset": {
                      borderColor: "#EFE1D1",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#EFE1D1",
                    },
                  }}
                />
                {/* <TextField
                  label="Role"
                  name="role"
                  value={formData.role}
                  onChange={handleInputChange}
                  variant="outlined"
                  required
                  sx={{
                    marginBottom: 2,
                    width: "100%",
                    "& input, & label": {
                      color: "#EFE1D1",
                    },
                    "& fieldset": {
                      borderColor: "#EFE1D1",
                    },
                    "&:hover fieldset": {
                      borderColor: "#EFE1D1",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#EFE1D1",
                    },
                  }}
                /> */}
                <FormControl
                  variant="outlined"
                  required
                  sx={{ width: "100%", marginBottom: 2 }}
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                      color: "#EFE1D1",
                      inputLabelRoot: {
                        color: "yellow",
                      },
                    }}
                  >
                    Role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="role"
                    value={formData.role} // Set value to formData.role
                    onChange={handleInputChange}
                    label="Roles"
                    sx={{
                      color: "#EFE1D1",
                      "& .MuiInput-input": {
                        color: "#EFE1D1",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#EFE1D1",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#EFE1D1",
                      },
                      "& .MuiSelect-icon": {
                        color: "#EFE1D1",
                      },
                      "&:focus": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#EFE1D1",
                        },
                      },
                    }}
                  >
                    <MenuItem value={"Solo100"}>Solo100</MenuItem>
                    <MenuItem value={"DAO Member"}>DAO Member</MenuItem>
                    <MenuItem value={"Not a Member"}>Not a Member</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  label="Weight"
                  name="weight"
                  value={formData.weight}
                  onChange={handleInputChange}
                  variant="outlined"
                  required
                  sx={{
                    marginBottom: 2,
                    width: "100%",
                    "& input, & label": {
                      color: "#EFE1D1",
                    },
                    "& fieldset": {
                      borderColor: "#EFE1D1",
                    },
                    "&:hover fieldset": {
                      borderColor: "#EFE1D1",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#EFE1D1",
                    },
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ width: "100%" }}
                >
                  Create
                </Button>
              </form>
            </MyModal>
          )}
          {editeable && (
            <MyModal open={editeable} setOpen={setEditeable}>
              {loading ? (
                <Loader />
              ) : (
                // <form onSubmit={handleUpdateSubmit}>
                //   <Typography
                //     // variant="h6"
                //     sx={{
                //       textAlign: "center",
                //       fontWeight: 600,
                //       fontSize: "14",
                //       marginBottom: 2,
                //     }}
                //   >
                //     Update Collection
                //   </Typography>
                //   <TextField
                //     label="Collection Name"
                //     name="collection_name"
                //     value={formData.collection_name}
                //     onChange={handleInputChange}
                //     variant="outlined"
                //     required
                //     sx={{
                //       marginBottom: 2,
                //       width: "100%",
                //       "& input, & label": {
                //         color: "#EFE1D1",
                //       },
                //       "& fieldset": {
                //         borderColor: "#EFE1D1",
                //       },
                //       "&:hover fieldset": {
                //         borderColor: "#EFE1D1",
                //       },
                //       "&.Mui-focused fieldset": {
                //         borderColor: "#EFE1D1",
                //       },
                //     }}
                //   />
                //   <TextField
                //     label="Taxon ID"
                //     name="taxonid"
                //     value={formData.taxonid}
                //     onChange={handleInputChange}
                //     variant="outlined"
                //     required
                //     sx={{
                //       marginBottom: 2,
                //       width: "100%",
                //       "& input, & label": {
                //         color: "#EFE1D1",
                //       },
                //       "& fieldset": {
                //         borderColor: "#EFE1D1",
                //       },
                //       "&:hover fieldset": {
                //         borderColor: "#EFE1D1",
                //       },
                //       "&.Mui-focused fieldset": {
                //         borderColor: "#EFE1D1",
                //       },
                //     }}
                //   />
                //   <InputLabel id="demo-simple-select-label" >Role</InputLabel>
                //   <Select
                //     labelId="demo-simple-select-label"
                //     id="demo-simple-select"
                //     value={formData.role}
                //     label="Age"
                //     onChange={handleInputChange}
                //   >
                //     <MenuItem value={10}>Solo 100</MenuItem>
                //     <MenuItem value={20}>DAO Member</MenuItem>
                //     <MenuItem value={30}>Not a Member</MenuItem>
                //   </Select>
                //   {/* <TextField
                //     label="Role"
                //     name="role"
                //     value={formData.role}
                //     onChange={handleInputChange}
                //     variant="outlined"
                //     required
                //     sx={{
                //       marginBottom: 2,
                //       width: "100%",
                //       "& input, & label": {
                //         color: "#EFE1D1",
                //       },
                //       "& fieldset": {
                //         borderColor: "#EFE1D1",
                //       },
                //       "&:hover fieldset": {
                //         borderColor: "#EFE1D1",
                //       },
                //       "&.Mui-focused fieldset": {
                //         borderColor: "#EFE1D1",
                //       },
                //     }}
                //   /> */}
                //   <TextField
                //     label="Weight"
                //     name="weight"
                //     value={formData.weight}
                //     onChange={handleInputChange}
                //     variant="outlined"
                //     required
                //     sx={{
                //       marginBottom: 2,
                //       width: "100%",
                //       "& input, & label": {
                //         color: "#EFE1D1",
                //       },
                //       "& fieldset": {
                //         borderColor: "#EFE1D1",
                //       },
                //       "&:hover fieldset": {
                //         borderColor: "#EFE1D1",
                //       },
                //       "&.Mui-focused fieldset": {
                //         borderColor: "#EFE1D1",
                //       },
                //     }}
                //   />
                //   <Button
                //     variant="contained"
                //     color="primary"
                //     type="submit"
                //     sx={{ width: "100%" }}
                //   >
                //     Update
                //   </Button>
                // </form>
                <form onSubmit={handleUpdateSubmit}>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: 600,
                      fontSize: "14px",
                      marginBottom: 2,
                    }}
                  >
                    Update Collection
                  </Typography>
                  <TextField
                    label="Collection Name"
                    name="collection_name"
                    value={formData.collection_name}
                    onChange={handleInputChange}
                    variant="outlined"
                    required
                    sx={{
                      marginBottom: 2,
                      width: "100%",
                      "& input, & label": {
                        color: "#EFE1D1",
                      },
                      "& fieldset": {
                        borderColor: "#EFE1D1",
                      },
                      "&:hover fieldset": {
                        borderColor: "#EFE1D1",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#EFE1D1",
                      },
                    }}
                  />
                  <TextField
                    label="Taxon ID"
                    name="taxonid"
                    value={formData.taxonid}
                    onChange={handleInputChange}
                    variant="outlined"
                    required
                    sx={{
                      marginBottom: 2,
                      width: "100%",
                      "& input, & label": {
                        color: "#EFE1D1",
                      },
                      "& fieldset": {
                        borderColor: "#EFE1D1",
                      },
                      "&:hover fieldset": {
                        borderColor: "#EFE1D1",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#EFE1D1",
                      },
                    }}
                  />
                  <FormControl
                    variant="outlined"
                    required
                    sx={{ width: "100%", marginBottom: 2 }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{
                        color: "#EFE1D1",
                        inputLabelRoot: {
                          color: "yellow",
                        },
                      }}
                    >
                      Role
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="role"
                      value={formData.role} // Set value to formData.role
                      onChange={handleInputChange}
                      label="Roles"
                      sx={{
                        color: "#EFE1D1",
                        "& .MuiInput-input": {
                          color: "#EFE1D1",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#EFE1D1",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#EFE1D1",
                        },
                        "& .MuiSelect-icon": {
                          color: "#EFE1D1",
                        },
                        "&:focus": {
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#EFE1D1",
                          },
                        },
                      }}
                    >
                      <MenuItem value={"Solo100"}>Solo100</MenuItem>
                      <MenuItem value={"DAO Member"}>DAO Member</MenuItem>
                      <MenuItem value={"Not a Member"}>Not a Member</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    label="Weight"
                    name="weight"
                    //value={formData.weight == 0 ? 0 : formData.weight}
                    value={formData.weight}
                    onChange={handleInputChange}
                    variant="outlined"
                    required
                    sx={{
                      marginBottom: 2,
                      width: "100%",
                      "& input, & label": {
                        color: "#EFE1D1",
                      },
                      "& fieldset": {
                        borderColor: "#EFE1D1",
                      },
                      "&:hover fieldset": {
                        borderColor: "#EFE1D1",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#EFE1D1",
                      },
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{ width: "100%" }}
                  >
                    Update
                  </Button>
                </form>
              )}
            </MyModal>
          )}
          {deleteable && (
            <MyModal
              open={deleteable}
              setOpen={setDeleteable}
              closer={() => setDeleteable(false)}
              getCollections={getCollections}
              selectedCollectionId={selectedCollectionId}
              title="Update Collection"
              callFunction={handleDeleteData}
              // updateOpen={editeable}
              // setUpdateOpen={setEditeable}
            >
              <Typography
                // variant="h6"
                sx={{
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: "14",
                  marginBottom: 2,
                }}
              >
                Delete Collection
              </Typography>
              {/* <Grid container>
                <Grid xs={12}></Grid>
              </Grid> */}

              <Typography sx={{ color: "white", marginTop: "10px" }}>
                Are you sure you want to delete?
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "right" }}>
                <Button
                  sx={{ color: "white", marginLeft: "250px" }}
                  onClick={(e) => handleDeleteData(selectedCollectionId)}
                >
                  Yes
                </Button>
                <Button
                  sx={{ color: "white" }}
                  onClick={(e) => setDeleteable(false)}
                >
                  No
                </Button>
              </Box>
            </MyModal>
          )}
          {/* {open && <Modal />} */}
          {/* <CustomTable columns={POLL_TABLE_COLUMNS} rows={polls} /> */}
          <Box sx={{}}> </Box>
        </Box>
      )}
    </>
  );
};

export default Collection;
