import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Loader from "../Loader/Loader";
import {
  getUserVotesWithPagination,
  getVotesPerPoll,
} from "../APIRequests/PollAPIs";
import Header from "../Header/Header";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PollChartDataTable from "./PollChartDataTable";

const BarChartPolling = () => {
  const [data, setData] = useState([]);
  const [loaderState, setLoaderState] = useState(false);
  const [pollTitle, setPollTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [userVotes, setUserVotes] = useState([]);
  const [totalPaginations, setTotalPaginations] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [userPaginationData, setUserPaginationData] = useState({
    limit: 10,
    offset: 0,
    search: "",
  });
  const params = useParams();

  const navigate = useNavigate();

  const getUserVotes = async (id, data) => {
    console.log(params, "params....");
    console.log(params.data, "User votes for this id");
    setLoading(true);

    //const users = await getUserData();
    const userVotes = await getUserVotesWithPagination(params.data, data);
    console.log(userVotes, "Got this from user votes API");
    let totalPag = userVotes.totalCount / 1;
    totalPag = Math.ceil(totalPag);
    setTotalPaginations(totalPag);
    setUserVotes(userVotes.userVotes);
    console.log(userVotes);

    setTimeout(() => {
      setLoading(false);
      console.log(userVotes);
    }, 2000);
  };

  const pageChangeHandler = async (event, pageNumber) => {
    console.log(pageNumber);
    setSelectedPage(pageNumber);
    setUserPaginationData({
      ...userPaginationData,
      offset: pageNumber - 1,
    });
    console.log(userPaginationData, "Sending these values");
    const response = await getUserVotesWithPagination(params.data, {
      limit: 10,
      offset: pageNumber - 1,
      search: "",
    });
    console.log(response, "responseresponseresponseresponseresponse");
    setUserVotes(response.userVotes);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch data from the backend
        const votesInfo = await getVotesPerPoll(params.data);
        console.log("🚀 ~ fetchData ~ votesInfo:", votesInfo);
        console.log(votesInfo, " These are the votes coming from backend!");
        console.log(votesInfo.data.userVotes, "show this data in table");
        // setUserVotes(votesInfo.data.userVotes);
        console.log(votesInfo.data.title.title, " We are voting on this!");
        setPollTitle(votesInfo.data.title.title);
        console.log(pollTitle, "Poll title in state");
        // Extract the necessary data for the chart
        const categories = votesInfo.data.options.map((option) => option.title);
        // console.log("🚀 ~ fetchData ~ categories:", categories)

        // const voteCounts = votesInfo.data.options.map((option) =>
        //   parseInt(option.no_of_votes)
        // );
        //   const voteCounts = votesInfo.data.options.map((option) =>
        //   option !== null ? parseInt(option.no_of_votes) || 0 : 0
        // );

        const voteCounts = votesInfo.data.options.map((option) =>
          option !== null ? (option.no_of_votes || "0") + "%" : "0%"
        );

        // Create the options and series for the chart
        const options = {
          chart: {
            id: votesInfo.data.title.title,
          },

          yaxis: [
            {
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
                color: "#fff",
              },

              labels: {
                style: {
                  colors: "white",
                },
                formatter: function (value) {
                  return value + "%"; // Append '%' to the y-axis labels
                },
              },
              title: {
                // text: "Option Names", //
                text: "Votes Percentage",
                style: {
                  color: "#fff",
                },
              },
              min: 0, // Set the minimum value for the y-axis
              max: 100, // Set the maximum value for the y-axis
            },
          ],

          xaxis: {
            categories: categories,
            title: {
              // text: "Number of Votes",
              text: "Option Names",
              style: {
                color: "#fff",
              },
            },
            labels: {
              show: true,
              style: {
                colors: "#fff",
              },
            },
          },

          plotOptions: {
            bar: {
              //borderRadius: 4,

              /// change the positon of the bars
              horizontal: false,
            },
          },
          dataLabels: {
            enabled: true, // Enable data labels
            offsetY: 20, // Adjust the offset to position labels below bars
          },
        };

        const series = [
          {
            name: "Votes Percentage",
            data: voteCounts,
            // data: [30, 40, 45, 50, 49, 60, 70, 91]
          },
        ];
        // Update the state with the fetched data
        setData({ options, series });
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoaderState(true); // Set loader state if there's an error
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    getUserVotes(params.data, { limit: 10, offset: 0, search: "" });
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "10vh",
          background: "#2c2c2c",
        }}
      >
        <Box
          sx={{
            paddingLeft: "17vw",
          }}
        >
          <Header heading={"POLLING RESULTS"} />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "left",
            marginLeft: "17vw",
            // width: "50%",
          }}
        >
          <Grid
            container
            sx={{
              // paddingTop: "2vh",
              // width: "90vw",
              width: "98%",
              display: "flex",
              alignItems: "center",
              justifyContent: "spaceAround",
            }}
          >
            <Grid xs={6}>
              <Typography
                sx={{
                  fontSize: 24,
                  color: "white",
                  fontWeight: 800,
                  marginTop: "30px",
                }}
              >
                {pollTitle}
              </Typography>
              {/* <Header heading={pollTitle} /> */}
            </Grid>
            <Grid
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "right",
                // marginRight: "3px",
                paddingTop: "20px",
              }}
            >
              {" "}
              <Button
                variant="outlined"
                onClick={(e) => navigate("/polls")}
                sx={{
                  marginTop: "10px",
                  background: "transparent",
                  height: "40px",
                  color: "#EFE1D1",
                  borderColor: "#EFE1D1",
                  fontWeight: 1000,
                  display: "flex", // Use flexbox
                  alignItems: "center", // Vertically center content
                  "&:hover": {
                    background: "#EFE1D1",
                    border: "1px solid ",
                    color: "#2c2c2c",
                  },
                }}
              >
                <ArrowBackIcon sx={{ fontWeight: 1000, marginBottom: "2px" }} />
              </Button>{" "}
            </Grid>
          </Grid>
          {/* <Header heading={pollTitle} /> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            marginRight: "40px",
            marginTop: "30px",
          }}
        ></Box>
      </Box>

      <Box
        sx={{
          width: "80vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "right",
          marginLeft: "17.5vw",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "right",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "right",
              // height: 100,
              alignItems: "center",
            }}
          >
            {/* <CreateButton /> */}
          </Box>
        </Box>

        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            justifyContent: "center",
            // paddingTop: "15vh",
          }}
        >
          {loading ? (
            <Loader />
          ) : data.options && data.series ? (
            <Chart
              options={data.options}
              series={data.series}
              type="bar"
              width={700}
              height={350}
            />
          ) : (
            <p>No data available</p>
          )}
        </Container>
        <Container
          maxWidth="md"
          sx={
            {
              // paddingTop: "15vh",
            }
          }
        >
          <Box sx={{ py: 2 }}>
            {userVotes ? (
              <>
                <PollChartDataTable data={userVotes} />
                <Stack
                  spacing={2}
                  sx={{
                    marginTop: "10px",
                  }}
                >
                  <Pagination
                    count={totalPaginations < 10 ? 1 : totalPaginations}
                    page={selectedPage}
                    onChange={(event, pageNumber) =>
                      pageChangeHandler(event, pageNumber)
                    }
                    variant="outlined"
                    shape="rounded"
                    sx={{
                      display: "flex",
                      justifyContent: "right",
                      marginTop: "10px",
                      "& .MuiPaginationItem-page": {
                        color: "#EFE1D1", // Change button text color
                      },
                      // "& .MuiPaginationItem-root.Mui-selected": {
                      //   backgroundColor: "black", // Change selected button background color
                      // },
                      "& .MuiPaginationItem-root": {
                        borderColor: "#EFE1D1", // Change button border color
                      },
                      "& .Mui-selected": {
                        //backgroundColor: "#2c2c2c", // Change selected button background color
                        background: "#EFE1D1 !important", // Change selected button background color
                        color: "#2c2c2c !important",
                      },

                      "& .MuiPaginationItem-icon": {
                        color: "#EFE1D1", // Change arrow color
                      },
                      "& .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected":
                        {
                          background: "#EFE1D1 !important", // Change selected button background color
                          color: "#2C2C2C !important",
                        },
                      // "& .css-1wh1znk .Mui-selected ": {
                      //   //backgroundColor: "#2C2C2C", // Change selected button background color
                      //   background: "#EFE1D1 !important", // Change selected button background color
                      //   color: "#2C2C2C",
                      // },
                    }}
                  />
                </Stack>
              </>
            ) : (
              <Typography
                sx={{
                  color: "white",
                  textAlign: "center",
                  fontSize: 18,
                  fontWeight: 800,
                }}
              >
                No data found
              </Typography>
            )}
          </Box>
        </Container>

        <Box sx={{}}></Box>
      </Box>
    </>
  );
};

export default BarChartPolling;
