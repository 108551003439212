import axios from "axios";
import { base_url } from "../../base_url";
const createUserApiURL = `${base_url}/user/create`;
const getUserApiURL = `${base_url}/api/user`;
const getUserByIdApiURL = `${base_url}/api/user/34`;
export const createUserData = async (data) => {
  try {
    console.log("Create users API call");
    //const { data } = await axios.get(`${base_url}collection/`);
    await axios.post(`${base_url}user/create`, data).then((res) => {
      console.log(res);
      console.log(res.data);
    });
  } catch (e) {
    console.log(e);
  }
};
export const getUsersWithPagination = async (data) => {
  try {
    console.log("Getting user data with pagination");
    const response = await axios.post(`${base_url}user`, data);
    console.log(response);
    console.log(response.data);
    return response.data;
  } catch (e) {
    console.log(e);
    return null; // Handle errors appropriately and return null or an error message
  }
};
export const updateUserData = async (id, data) => {
  try {
    console.log("updating user: ", { id, data });
    const res = await axios.put(`${base_url}user/update/${id}`, data);
    console.log(res, "response");
    return res;
  } catch (e) {
    console.log(e);
  }
};
export const deleteUserData = async (id) => {
  try {
    console.log("deleting this id: ", id);
    const res = await axios.delete(`${base_url}user/${id}`);
    console.log(res, "response");
    return res;
  } catch (e) {
    console.log(e);
  }
};
export const searchUserData = async (wallet) => {
  try {
    console.log("searching this user: ", wallet);
    const { data } = await axios.post(`${base_url}user/`, wallet);
    console.log("response: ", data);

    if (!data) {
      // throw new Error("User not found"); // Throw an error if user is not found
      console.log("User not found!");
    }

    return data;
  } catch (e) {
    console.log(e);
    // throw e; // Re-throw the error so it can be caught elsewhere
  }
};
export const updateUserRecord = async () => {
  console.log(`${base_url}user/update`);
  try {
    console.log("calling updateUserRecord API");
    console.log(`${base_url}user/update`);
    const res = await axios.get(`${base_url}user/update`);
    console.log(res, "response");
    return res;
  } catch (e) {
    console.log(e);
  }
};
//----------------------login api call-------------------

export const login = async (data) => {
  try {
    console.log("login API call");
    console.log(data, "check this address to login!");
    const response = await axios.post(`${base_url}login`, data);
    console.log(response.data, "response from API");

    return response; // Return the entire response object
  } catch (e) {
    console.log(e);
    // Handle the error here if needed
  }
};
