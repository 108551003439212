import React, { useState } from "react";
import CustomTable from "../Components/shared/CustomTable";
import { ADMIN_TABLE_COLUMNS } from "../constants";
import Buttons from "../Components/Buttons";
import AdminTable from "../Components/AdminTable/AdminTable";
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { rows } from "../constants/data";
import MyModal from "../Components/Modal/Modal";
import Header from "../Components/Header/Header";
import Loader from "../Components/Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateButton from "../Components/buttons/CreateButton";
import Form from "../Components/forms/Form";
import {
  deleteAdminData,
  getAdminData,
  updateAdminData,
} from "../Components/APIRequests/AdminAPIs";

import { useEffect } from "react";
const Admins = () => {
  const UserFields = [
    // { label: "Wallet Address", name: "wallet" },
    //{ label: "Discord ID", name: "discord_id" },
    { label: "Role", name: "roles" },
    // { label: "User Role", name: "user_role" },
    //{ label: "Updated By", name: "updated_by" },
  ];
  const initialFormData = {
    //wallet: "",
    // discord_id: "",
    roles: "",
    //user_role: "",
    // updated_by: "",
  };
  const [userState, setUserState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("user");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [adminState, setAdminState] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editeable, setEditeable] = useState(false);
  const [deleteable, setDeleteable] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const getAdmins = async () => {
    setLoading(true);
    const adminWalletAddress = localStorage.getItem("address");
    const admins = await getAdminData(adminWalletAddress);
    setAdminState(admins);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    console.log(adminState);
  };
  const handleEditClick = (item) => {};
  const handleDeleteClick = (item) => {};
  const handleCreateData = (formData) => {
    // Handle create data submission logic
    console.log("Form submitted with data:", formData);
  };
  const createUser = () => {
    console.log("calling create ");
    setShowModal(true);
    // handleOpen();
  };
  const handleDeleteData = async (selectedUserId) => {
    setLoading(true);

    console.log("deleting this id:", selectedUserId);
    await deleteAdminData(selectedUserId);
    setLoading(false);
    setDeleteable(false);
    getAdmins();
    toast.success("Admin deleted successfully!");
  };
  const handleUpdateSubmit = async (event) => {
    event.preventDefault();
    console.log("The date am sending to API is: ", formData);
    setFormData({ roles: role });
    await updateAdminData(selectedUserId, { roles: role });
    setEditeable(false);
    getAdmins();
    console.log("Form submitted with data:", formData);
    toast.success("Admin updated successfully!");
  };
  const getUserId = (id) => {
    console.log("get user id function called!");
    setSelectedUserId(id);
    const currentUser = adminState.users.find((user) => user.user_id === id);
    console.log(currentUser);
    if (currentUser) {
      console.log(currentUser.roles);
      setRole(currentUser.roles); // Set the current role
    }
    console.log(role);
    console.log("called getUserId function");
  };
  const handleRoleChange = (event) => {
    const newRole = event.target.value;
    setRole(newRole);
    //setRole(event.target.value);
    console.log(newRole, "will be updated role");
  };
  useEffect(() => {
    getAdmins();
  }, []);
  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "12vh",
          background: "#2c2c2c",
        }}
      >
        <Box
          sx={{
            paddingLeft: "17.5vw",
          }}
        >
          <Header heading={"ADMINS"} />
        </Box>
      </Box>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {loading ? (
        <Box
          sx={{
            width: "80vw",
            display: "flex",
            justifyContent: "center",
            height: "60vh",
            alignItems: "center",
            marginLeft: "17.5vw",
          }}
        >
          <Loader />
        </Box>
      ) : (
        <Box
          sx={{
            width: "80vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "right",
            marginLeft: "17.5vw",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "right",
            }}
          >
            {/* <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "left",
              }}
            >
              <Header heading={"ADMINS"} />
            </Box> */}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "right",
                height: 23,
                alignItems: "center",
              }}
            >
              {/* <CreateButton
                // onClick={(e) => {
                //   //setShowModal(true);
                //   createCollection();
                // }}
                callFunction={createUser}
                // handleClick={(e) => createCollection(e)}
              /> */}
            </Box>
          </Box>
          {adminState?.users ? (
            <AdminTable
              admins={adminState?.users}
              onEditClick={handleEditClick}
              onDeleteClick={handleDeleteClick}
              editState={editeable}
              setEditState={setEditeable}
              deleteState={deleteable}
              setDeleteState={setDeleteable}
              onClick={getUserId}
            />
          ) : (
            <Typography
              sx={{
                color: "white",
                textAlign: "center",
                fontSize: 18,
                fontWeight: 800,
              }}
            >
              No data found
            </Typography>
          )}

          {/* <CustomTable columns={POLL_TABLE_COLUMNS} rows={polls} /> */}
          {/* {showModal && (
            <MyModal open={showModal} setOpen={setShowModal}>
              <Form
                fields={userFields}
                onSubmit={handleCreateData}
                title="Create User"
              />
            </MyModal>
          )} */}
          {editeable && (
            <MyModal
              open={editeable}
              setOpen={setEditeable}
              // updateOpen={editeable}
              // setUpdateOpen={setEditeable}
            >
              <form onSubmit={handleUpdateSubmit}>
                <Typography
                  // variant="h6"
                  sx={{ textAlign: "center", fontWeight: 600, fontSize: "14" }}
                >
                  Update Admin
                </Typography>
                {UserFields.map((field) => (
                  // <TextField
                  //   key={field.name}
                  //   style={inputStyle}
                  //   label={field.label}
                  //   name={field.name}
                  //   value={formData[field.name]}
                  //   onChange={handleInputChange}
                  //   variant="outlined"
                  //   required
                  //   InputProps={{ disableUnderline: true }}
                  // />
                  <Grid
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      flexDirection: "column",
                      marginY: "10px",
                    }}
                  >
                    <Grid
                      xs={12}
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      Change admin role?
                    </Grid>
                    <Grid
                      xs={12}
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      {/* <Switch
                        checked={isChecked}
                        onChange={handleSwitchChange}
                      /> */}

                      <TextField
                        select
                        value={role}
                        label="Roles"
                        fullWidth
                        onChange={handleRoleChange}
                        variant="filled"
                        InputLabelProps={{
                          style: {
                            color: "#EFE1D1",
                          },
                        }}
                        SelectProps={{
                          style: {
                            color: "#EFE1D1",
                          },
                        }}
                        sx={{
                          color: "#EFE1D1",
                          background: "#2c2c2c",
                          borderRadius: "2px",
                          "& .MuiOutlinedInput-root": {
                            // borderColor: "#EFE1D1",
                            "&.Mui-focused fieldset": {
                              // border: "none",
                              borderColor: "#EFE1D1",
                            },
                          },
                        }}
                      >
                        <MenuItem value={"user"}>user</MenuItem>
                        <MenuItem value={"admin"}>admin</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid
                      xs={12}
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        sx={{ marginTop: "10px" }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                ))}
              </form>

              {/* ----------------------- */}
            </MyModal>
          )}
          {deleteable && (
            <MyModal
              open={deleteable}
              setOpen={setDeleteable}
              closer={() => setDeleteable(false)}
              getUsers={getAdmins}
              // selectedCollectionId={selectedCollectionId}
              title="Update Admin"
              // callFunction={handleDeleteData}
              // updateOpen={editeable}
              // setUpdateOpen={setEditeable}
            >
              <Typography
                // variant="h6"
                sx={{ textAlign: "center", fontWeight: 600, fontSize: "14" }}
              >
                Delete Admin
              </Typography>
              <Grid container>
                <Grid xs={12}>
                  <Typography sx={{ color: "white" }}>
                    Are you sure you want to delete?
                  </Typography>
                </Grid>
                <Box sx={{ display: "flex", justifyContent: "right" }}>
                  <Button
                    sx={{ color: "white", marginLeft: "250px" }}
                    onClick={(e) => handleDeleteData(selectedUserId)}
                  >
                    Yes
                  </Button>
                  <Button
                    sx={{ color: "white" }}
                    onClick={(e) => setDeleteable(false)}
                  >
                    No
                  </Button>
                </Box>
              </Grid>
            </MyModal>
          )}
          <Box sx={{}}> </Box>
        </Box>
      )}
    </>
  );
};

export default Admins;
