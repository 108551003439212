import axios from "axios";
import { base_url } from "../../base_url";
export const getAdminData = async (address) => {
  try {
    const { data } = await axios.post(`${base_url}user/admins`, {
      wallet: address,
    });
    //const { data } = await axios.get(getCollectionsURL);
    console.log(data, "Admin API data response");
    return data;
  } catch (e) {
    console.log(e);
  }
};
export const deleteAdminData = async (id) => {
  try {
    console.log("deleting this id: ", id);
    const res = await axios.delete(`${base_url}user/${id}`);
    console.log(res, "response");
    return res;
  } catch (e) {
    console.log(e);
  }
};
export const updateAdminData = async (id, data) => {
  try {
    console.log("updating user: ", { id, data });
    const res = await axios.put(`${base_url}user/update/${id}`, data);
    console.log(res, "response");
    return res;
  } catch (e) {
    console.log(e);
  }
};
